<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card ref="formCard">
        <v-card-title>
          <span class="headline" v-if="formType === 'UPDATE'">Update Account</span>
          <span class="headline" v-else>Create New Account</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formEntry">
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">Details</v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step v-if="isCredit" :complete="e1 > 2" step="2">
                  Add'l Details
                </v-stepper-step>
                <v-divider v-if="isCredit"></v-divider>
                <v-stepper-step v-if="isCredit" :complete="e1 > 3" step="3">
                  Terms
                </v-stepper-step>
                <v-divider v-if="isCredit"></v-divider>
                <v-stepper-step v-if="isCredit" step="4" :complete="isFormComplete">
                  Review
                </v-stepper-step>

                <v-stepper-step v-if="!isCredit"
                                :complete="e1 > 2" step="2">
                  Terms
                </v-stepper-step>
                <v-divider v-if="!isCredit"></v-divider>
                <v-stepper-step v-if="!isCredit" step="3" :complete="isFormComplete">
                  Review
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form ref="formEntryOne">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-autocomplete label="Client*" required data-cy="client-id"
                                          v-model="form.clientId"
                                          :items="memberList"
                                          item-text="displayName"
                                          item-value="id"
                                          :readonly="form.active === true"
                                          :disabled="form.active === true"
                                          :rules="[rules.requiredSelection]"></v-autocomplete>
                        </v-flex>
                        <v-flex xs12>
                          <v-autocomplete label="Wallet*" required data-cy="product-id"
                                          v-model="form.productId"
                                          :items="walletList"
                                          item-text="name"
                                          item-value="id"
                                          @change="queryJobLinksCreate"
                                          :readonly="form.active === true"
                                          :disabled="form.active === true"
                                          :rules="[rules.requiredSelection]"></v-autocomplete>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <div class="text-right stepper-1-btns">
                      <v-btn color="primary" @click="next(1)">
                        Continue
                      </v-btn>
                    </div>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content :step="isCredit ? '3' : '2'">
                  <v-form ref="formEntryTwo">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm6 md6>
                          <v-text-field label="Max Credit Limit*"
                                        required type="number"
                                        data-cy="credit-limit"
                                        v-model="form.creditLimit"
                                        :prefix="currency"
                                        :rules="[rules.requiredSelection]"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field label="Minimum opening balance"
                                        type="number"
                                        data-cy="min-required-opening-balance"
                                        :prefix="currency"
                                        :readonly="form.active === true"
                                        :disabled="form.active === true"
                                        v-model="form.minRequiredOpeningBalance"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field label="Nominal annual interest*"
                                        suffix="%" type="number" required
                                        data-cy="nominal-annual-interest-rate"
                                        v-model="form.nominalAnnualInterestRate"
                                        :readonly="form.active === true"
                                        :disabled="form.active === true"
                                        :rules="[rules.requiredField]"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-autocomplete label="Days in year*"
                                          required
                                          data-cy="interest-calculation-days-in-year-type"
                                          v-model="form.interestCalculationDaysInYearType"
                                          :items="daysInYearOptionList"
                                          item-text="text"
                                          item-value="value"
                                          :readonly="form.active === true"
                                          :disabled="form.active === true"
                                          :rules="[rules.requiredSelection]"></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-autocomplete label="Interest calculated using*"
                                          required
                                          data-cy="interest-calculation-type"
                                          v-model="form.interestCalculationType"
                                          :items="interestCalculationMethodList"
                                          item-text="text"
                                          item-value="value"
                                          :readonly="form.active === true"
                                          :disabled="form.active === true"
                                          :rules="[rules.requiredSelection]"></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-switch :label="`Apply withdrawal fee for transfers`"
                                    inset
                                    data-cy="withdrawal-fee-for-transfers"
                                    v-model="form.withdrawalFeeForTransfers"
                                    :readonly="form.active === true"
                                    :disabled="form.active === true"></v-switch>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-autocomplete label="Interest compounding period*"
                                          required
                                          data-cy="interest-compounding-period-list"
                                          v-model="form.interestCompoundingPeriodType"
                                          :items="interestCompoundingPeriodList"
                                          item-text="text"
                                          item-value="value"
                                          :readonly="form.active === true"
                                          :disabled="form.active === true"
                                          :rules="[rules.requiredSelection]"></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-autocomplete label="Interest posting period*"
                                          required
                                          data-cy="interest-posting-period-type"
                                          v-model="form.interestPostingPeriodType"
                                          :items="interestPostingPeriodList"
                                          item-text="text"
                                          item-value="value"
                                          :readonly="form.active === true"
                                          :disabled="form.active === true"
                                          :rules="[rules.requiredSelection]"></v-autocomplete>
                        </v-flex>
                        <v-layout v-show="!isCredit">
                          <v-flex xs12 sm6 md6>
                            <v-text-field label="Lock-in period"
                                          type="number"
                                          data-cy="lockin-period-frequency"
                                          v-model="form.lockinPeriodFrequency"
                                          :readonly="form.active === true"
                                          :disabled="form.active === true"></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                            <v-autocomplete label="Lock-in period type"
                                            data-cy="lockin-period-frequency-type"
                                            v-model="form.lockinPeriodFrequencyType"
                                            :items="lockinPeriodFrequencyTypeList"
                                            item-text="text"
                                            item-value="value"
                                            :readonly="form.active === true"
                                            :disabled="form.active === true"></v-autocomplete>
                          </v-flex>
                        </v-layout>
                        <v-flex xs12 sm6 md6>
                          <v-switch :label="`Enforce minimum balance`"
                                    inset
                                    data-cy="enforce-min-required-balance"
                                    v-model="form.enforceMinRequiredBalance"
                                    :readonly="form.active === true"
                                    :disabled="form.active === true"></v-switch>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field label="Minimum balance*"
                                        required type="number"
                                        data-cy="min-required-balance"
                                        v-model="form.minRequiredBalance"
                                        :prefix="currency"
                                        :readonly="form.active === true
                                            || form.enforceMinRequiredBalance === false"
                                        :disabled="form.active === true
                                            ||form.enforceMinRequiredBalance === false"
                                        :rules="[rules.requiredField]"></v-text-field>
                        </v-flex>
                        <v-layout v-show="!isCredit">
                          <v-flex xs12>
                            <v-switch :label="`Is Overdraft Allowed`"
                                      inset
                                      data-cy="allow-overdraft"
                                      v-model="form.allowOverdraft"
                                      :readonly="form.active === true"
                                      :disabled="form.active === true"></v-switch>
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field v-if="form.allowOverdraft === true"
                                          label="Nominal annual interest for overdraft"
                                          suffix="%" type="number"
                                          data-cy="nominal-annual-interest-rate-overdraft"
                                          v-model="form.nominalAnnualInterestRateOverdraft"
                                          :readonly="form.active === true"
                                          :disabled="form.active === true"></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field v-if="form.allowOverdraft === true"
                                          label="Maximum Overdraft Amount Limit"
                                          type="number"
                                          data-cy="overdraft-limit"
                                          v-model="form.overdraftLimit"
                                          :readonly="form.active === true"
                                          :disabled="form.active === true"></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field v-if="form.allowOverdraft === true"
                                          label="Min Overdraft Required For Interest Calculation"
                                          type="number"
                                          data-cy="min-overdraft-for-interest-calculation"
                                          v-model="form.minOverdraftForInterestCalculation"
                                          :readonly="form.active === true"
                                          :disabled="form.active === true"></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-container>
                    <div class="text-right stepper-1-btns">
                      <v-btn color="warning" class="mr-2"
                             @click="isCredit ? (e1 = 2) : (e1 = 1)">
                        Back
                      </v-btn>
                      <v-btn color="primary"
                             @click="isCredit ? next(3) : next(2)">
                        Continue
                      </v-btn>
                    </div>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content v-if="isCredit" step="2">
                  <v-form ref="formEntryFour">
                    <v-container grid-list-md>
                      <v-layout wrap child-flex>
                        <v-dialog persistent hide-overlay max-width="500px"
                                  v-model="loading">
                          <v-card color="primary" dark>
                            <v-card-text>
                              Please stand by
                              <v-progress-linear indeterminate color="white" class="mb-0">
                              </v-progress-linear>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                            <v-row>
                              <v-col cols="5" align="center">Jobs</v-col>
                              <v-col cols="5" align="center">Linked Accounts</v-col>
                              <v-col cols="2" align="right">
                                <v-btn fab dark x-small color="green"
                                       @click.stop="addLinkedAccounts()">
                                  <v-icon dark medium>mdi-plus</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            </thead>
                            <tbody>
                            <v-row v-for="(n, index) in linkedAccountFields"
                                   :key="index">
                              <v-col cols="5" align="center">
                                <v-autocomplete no-filter clearable deletable-chips small-chips
                                                required
                                                data-cy="jobs"
                                                v-model="form.jobs[n]"
                                                :items="availableLinkedJobs[n]"
                                                item-text="jobName"
                                                item-value="id"
                                                :rules="[rules.requiredSelection]"></v-autocomplete>
                              </v-col>
                              <v-col cols="5" align="center">
                                <v-autocomplete required clearable deletable-chips small-chips
                                                multiple
                                                data-cy="linked-accounts"
                                                v-model="form.linkedAccounts[form.jobs[n]]"
                                                :items="linkedAccounts[form.jobs[n]]"
                                                item-text="accountNumber"
                                                item-value="savingsAccountId"
                                                :rules="[rules.requiredSelection]"></v-autocomplete>
                              </v-col>

                              <v-col v-if="linkedAccountFields === n && linkedAccountFields > 1"
                                     cols="2" align="right">
                                <v-btn fab dark x-small color="red"
                                       @click.stop="removeLinkedAccounts()">
                                  <v-icon dark medium>mdi-close</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-layout>
                    </v-container>
                    <v-flex class="text-right stepper-2-btns">
                      <v-btn class="mr-2" @click="e1 = 1" color="warning">Back</v-btn>
                      <v-btn color="primary" @click="next(2)">Continue</v-btn>
                    </v-flex>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content :step="isCredit ? '4' : '3'">
                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <template>
                          <thead>
                          <tr>
                            <th class="text-left primary white--text title">Details</th>
                            <th class="primary"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>Client</td>
                            <td> {{ getDisplayName() }}</td>
                          </tr>
                          <tr>
                            <td>Wallet</td>
                            <td> {{ getProductName() }}</td>
                          </tr>
                          </tbody>
                        </template>
                        <template>
                          <thead>
                          <tr>
                            <th class="text-left primary white--text title">Terms</th>
                            <th class="primary"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>Nominal annual interest</td>
                            <td>{{ form.nominalAnnualInterestRate }}</td>
                          </tr>
                          <tr>
                            <td>Interest calculated using</td>
                            <td>
                              {{
                                form.interestCalculationType
                                  ? interestCalculationMethodList
                                    ? interestCalculationMethodList.find(
                                      (x) => x.value === form.interestCalculationType
                                    ).text
                                    : ""
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Max Credit limit</td>
                            <td>{{ form.creditLimit }}</td>
                          </tr>
                          <tr>
                            <td>Minimum opening balance</td>
                            <td>{{ form.minRequiredOpeningBalance }}</td>
                          </tr>
                          <tr>
                            <td>Interest compounding period</td>
                            <td>
                              {{
                                form.interestCompoundingPeriodType
                                  ? interestCompoundingPeriodList
                                    ? interestCompoundingPeriodList.find(
                                      (x) =>
                                        x.value === form.interestCompoundingPeriodType
                                    ).text
                                    : ""
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Interest posting period</td>
                            <td>
                              {{
                                form.interestPostingPeriodType
                                  ? interestPostingPeriodList
                                    ? interestPostingPeriodList.find(
                                      (x) =>
                                        x.value === form.interestPostingPeriodType
                                    ).text
                                    : ""
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Days in year</td>
                            <td>
                              {{
                                form.interestCalculationDaysInYearType
                                  ? daysInYearOptionList
                                    ? daysInYearOptionList.find(
                                      (x) =>
                                        x.value ===
                                        form.interestCalculationDaysInYearType
                                    ).text
                                    : ""
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Lock-in period</td>
                            <td>{{ form.lockinPeriodFrequency }}</td>
                          </tr>
                          <tr>
                            <td>Lock-in period type</td>
                            <td>
                              {{
                                form.lockinPeriodFrequencyType
                                  ? lockinPeriodFrequencyTypeList
                                    ? lockinPeriodFrequencyTypeList.find(
                                      (x) =>
                                        x.value === form.lockinPeriodFrequencyType
                                    ).text
                                    : ""
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Apply withdrawal fee for transfers</td>
                            <td>{{ form.withdrawalFeeForTransfers }}</td>
                          </tr>
                          <tr>
                            <td>Is Overdraft Allowed</td>
                            <td>{{ form.allowOverdraft }}</td>
                          </tr>
                          <tr v-if="form.allowOverdraft === true">
                            <td>Nominal annual interest for overdraft</td>
                            <td>{{ form.nominalAnnualInterestRateOverdraft }}</td>
                          </tr>
                          <tr v-if="form.allowOverdraft === true">
                            <td>Maximum Overdraft Amount Limit</td>
                            <td>{{ form.overdraftLimit }}</td>
                          </tr>
                          <tr v-if="form.allowOverdraft === true">
                            <td>Min Overdraft Required For Interest Calculation</td>
                            <td>{{ form.minOverdraftForInterestCalculation }}</td>
                          </tr>
                          <tr>
                            <td>Enforce minimum balance</td>
                            <td>{{ form.enforceMinRequiredBalance }}</td>
                          </tr>
                          <tr v-if="form.enforceMinRequiredBalance === true">
                            <td>Minimum balance</td>
                            <td>{{ form.minRequiredBalance }}</td>
                          </tr>
                          </tbody>
                        </template>
                        <template>
                          <thead>
                          <tr>
                            <th class="text-left primary white--text title">Add'l Details</th>
                            <th class="primary"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>Linked Jobs</td>
                            <td> {{ getLinkedJobs(form.jobs) }}</td>
                          </tr>
                          <tr>
                            <td>Linked Accounts</td>
                            <td> {{ getLinkedList(form.linkedAccounts) }}</td>
                          </tr>
                          </tbody>
                        </template>
                      </template>
                    </v-simple-table>
                  </template>
                  <v-flex class="text-right">
                    <v-btn class="mr-2" color="warning"
                           @click="isCredit ? (e1 = 3) : (e1 = 2)">
                      Back
                    </v-btn>
                    <v-btn color="primary" @click="save">Save</v-btn>
                  </v-flex>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>

          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" data-cy="close-account" text v-on:click="close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import API from "@/api/API.js";

import {
  DAYS_IN_YEAR_OPTIONS,
  INTEREST_CALCULATION_METHODS,
  INTEREST_COUMPOUNDING_PERIODS,
  INTEREST_POSTING_PERIODS,
  LOCKIN_PERIOD_FREQUENCY_TYPE,
} from "@/constants/savings_product.js";

export default {
  props: {
    isShow: Boolean,
    formType: String,
    loadingDialog: Boolean,
    selectedItem: Object,
    members: Array,
    wallets: Array,
    accounts: Array,
  },
  computed: {
    populatedForm() {
      return {
        id: this.form.id,
        clientId: this.form.clientId,
        productId: this.form.productId,
        formType: this.formType,
        newStatus: this.form.newStatus,
        active: this.form.active,

        nominalAnnualInterestRate: this.form.nominalAnnualInterestRate,
        interestCalculationType: this.form.interestCalculationType,
        minRequiredOpeningBalance: this.form.minRequiredOpeningBalance,
        interestCompoundingPeriodType: this.form.interestCompoundingPeriodType,
        interestPostingPeriodType: this.form.interestPostingPeriodType,
        interestCalculationDaysInYearType: this.form.interestCalculationDaysInYearType,
        lockinPeriodFrequency: this.form.lockinPeriodFrequency,
        lockinPeriodFrequencyType: this.form.lockinPeriodFrequencyType,
        withdrawalFeeForTransfers: this.form.withdrawalFeeForTransfers,
        allowOverdraft: this.form.allowOverdraft,
        enforceMinRequiredBalance: this.form.enforceMinRequiredBalance,
        minRequiredBalance: this.form.minRequiredBalance,
        nominalAnnualInterestRateOverdraft: this.form.nominalAnnualInterestRateOverdraft,
        overdraftLimit: this.form.overdraftLimit,
        minOverdraftForInterestCalculation: this.form.minOverdraftForInterestCalculation,
        creditLimit: this.form.creditLimit,
        linkedAccounts: this.form.linkedAccounts,
        jobs: this.form.jobs,
        isCredit: this.isCredit,
      };
    },
  },
  watch: {
    "form.productId": {
      handler(value) {
        const wallet = this.walletList.find((item) => item.id === value);
        if (wallet && wallet.accountType === "CREDIT") {
          this.isCredit = true;
          this.form.nominalAnnualInterestRate = wallet.nominalAnnualInterest;
          this.form.interestCalculationType = wallet.interestCalculationMethod;
          this.form.interestCompoundingPeriodType = wallet.interestCompoundingPeriod;
          this.form.interestPostingPeriodType = wallet.interestPostingPeriod;
          this.form.interestCalculationDaysInYearType = wallet.daysInYear;
          this.form.allowOverdraft = true;
          this.currency = wallet.currency;
        } else {
          this.isCredit = false;
        }
      },
      deep: true,
    },
    isShow: {
      handler(value) {
        this.dialog = value;
      },
    },
    loadingDialog: {
      handler(value) {
        this.loading = value;
      },
    },
    dialog: {
      handler(value) {
        if (value === true) {
          this.initializeForm();
        }
      },
    },
    members: {
      handler(value) {
        this.memberList = value ? value : [];
      },
    },
    wallets: {
      handler(value) {
        this.walletList = value ? value : [];
      },
    },
    accounts: {
      handler(value) {
        this.linkedAccounts = value ? value : [];
      },
    },
    "form.jobs": {
      handler(after, before) {
        var selected = [];
        for (var i = 1; i < after.length; i++) {
          selected = selected.concat(this.form.jobs[i]);
        }
        for (var i = 1; i < this.availableLinkedJobs.length; i++) {
          this.availableLinkedJobs[i] = this.linkedJobs.filter((job) => {
            if (this.form.jobs[i]) {
              return !selected.includes(job.id) || this.form.jobs[i] === job.id;
            } else {
              return !selected.includes(job.id);
            }
          });
        }
        this.availableLinkedJobs[after.length] = this.linkedJobs.filter((job) => {
          return !selected.includes(job.id);
        });
      },
      deep: true,
    },
  },
  data() {
    return {
      // selectableJobs: [],
      isCredit: false,
      linkedAccountFields: 1,
      memberList: this.members ? this.members : [],
      walletList: this.wallets ? this.wallets : [],
      interestCalculationMethodList: INTEREST_CALCULATION_METHODS,
      interestCompoundingPeriodList: INTEREST_COUMPOUNDING_PERIODS,
      interestPostingPeriodList: INTEREST_POSTING_PERIODS,
      daysInYearOptionList: DAYS_IN_YEAR_OPTIONS,
      lockinPeriodFrequencyTypeList: LOCKIN_PERIOD_FREQUENCY_TYPE,
      currency: undefined,
      dialog: this.isShow ?? false,
      loading: this.loadingDialog ?? false,

      linkedJobs: [],
      availableLinkedJobs: [],
      linkedAccounts: this.accounts ? this.accounts : [],

      form: {},
      rules: {
        requiredField: (v) => !!v || "Required field.",
        requiredSelection: (v) => !!v || "Required at least one selection",
        properEmail: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid. Ex. juandelacruz@apollo.com.ph",
      },
      e1: 1,
      isFormComplete: false,
    };
  },
  mounted() {
    this.initializeForm();
  },
  methods: {
    getDisplayName() {
      try {
        return this.form.clientId
          ? this.memberList
            ? this.memberList.find((x) => x.id === this.form.clientId).displayName
            : this.form.clientId
          : this.form.clientId;
      } catch (e) {
        return this.form.clientId;
      }
    },
    getProductName() {
      try {
        return this.form.productId
          ? this.walletList
            ? this.walletList.find((x) => x.id === this.form.productId).name
            : this.form.productId
          : this.form.productId;
      } catch (e) {
        return this.form.productId;
      }
    },
    getLinkedJobs(linkedList) {
      try {
        const linkedListArr = Object.keys(linkedList)
          .map((key) => {
            return linkedList[key];
          })
          .filter((notUndefined) => notUndefined !== undefined);
        return Object.keys(linkedListArr)
          .map((key) => {
            return this.linkedJobs
              ? this.linkedJobs.find((x) => x.id === linkedListArr[key]).jobName
              : linkedListArr[key];
          })
          .filter((notUndefined) => notUndefined !== undefined);
      } catch (e) {
        return e;
      }
    },
    getLinkedList(linkedList) {
      try {
        return (linkedList = Object.keys(linkedList)
          .map((key) => {
            return linkedList[key];
          })
          .filter((notUndefined) => notUndefined !== undefined));
      } catch (e) {
        return linkedList;
      }
    },
    getCurrentStatus() {
      if (this.selectedItem.status.active) {
        if (this.selectedItem.subStatus.block) {
          return "BLOCKED";
        } else {
          return "ACTIVATED";
        }
      } else if (this.selectedItem.status.approved) {
        return "APPROVED";
      } else if (this.selectedItem.status.closed) {
        return "CLOSED";
      } else if (this.selectedItem.status.rejected) {
        return "REJECTED";
      } else if (this.selectedItem.status.submittedAndPendingApproval) {
        return "PENDING APPROVAL";
      }
    },
    computeStatusList() {
      if (this.selectedItem.status.active) {
        if (this.selectedItem.subStatus.block) {
          return [{text: "UNBLOCK", value: "unblock"}];
        } else {
          return [{text: "BLOCK", value: "block"}];
        }
      } else if (this.selectedItem.status.approved) {
        return [
          {text: "UNDO APPROVAL", value: "undoApproval"},
          {text: "ACTIVATE", value: "activate"},
        ];
      } else if (this.selectedItem.status.closed) {
        return [{text: "ACTIVATE", value: "activate"}];
      } else if (this.selectedItem.status.rejected) {
        return [{text: "APPROVE", value: "approve"}];
      } else if (this.selectedItem.status.submittedAndPendingApproval) {
        return [{text: "APPROVE", value: "approve"}];
      }
    },
    close() {
      this.dialog = false;
      this.e1 = 1;
      this.$refs.formEntry.reset();
      this.$refs.formCard.$el.scrollIntoView({
        behavior: "smooth",
      });
      this.$emit("close-dialog", this.formType);
    },
    next(item) {
      if (item == 1 && this.$refs.formEntryOne.validate()) {
        this.e1 = 2;
        if (this.isCredit) {
          this.loading = true;
          if (this.formType === "CREATED") {
            console.log("queryJobLinksCreate");
            this.queryJobLinksCreate();
          } else if (this.formType === "UPDATE") {
            console.log("queryJobLinksCreate");
            this.queryJobLinksUpdate();
          }
        }
      } else if (!this.isCredit && item == 2 && this.$refs.formEntryTwo.validate()) {
        this.e1 = 3;
      } else if (this.isCredit && item == 2 && this.$refs.formEntryFour.validate()) {
        this.e1 = 3;
      } else if (!this.isCredit && item == 3) {
        this.e1 = 4;
      } else if (this.isCredit && item == 3 && this.$refs.formEntryTwo.validate()) {
        this.e1 = 4;
      }
    },
    async queryJobLinksCreate() {
      console.log(this.form);
      try {
        const listResponse = await API.getSelectableLinkedAccounts(this.form);
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          this.loading = false;
          console.log(listResponse);
          // convert object to array
          this.selectableJobs = Object.keys(listResponse.data.selectableJobs)
            .map((key) => {
              return listResponse.data.selectableJobs[key];
            })
            .filter((notUndefined) => notUndefined !== undefined);
          console.log(this.selectableJobs);
          this.linkedJobs = this.selectableJobs;
          var jobs = this.linkedJobs;
          this.availableLinkedJobs[1] = this.linkedJobs;

          var selectableAccounts = {};
          Object.keys(jobs).map(function (key, index) {
            selectableAccounts[jobs[key].id] =
              listResponse.data.selectableLinkedAccounts[jobs[key].id];
          });
          console.log(selectableAccounts);
          this.linkedAccounts = selectableAccounts;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async queryJobLinksUpdate() {
      try {
        const listResponse = await API.getSelectableJobs(this.form);
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          // convert object to array
          console.log(listResponse.data.selectableJobs);
          this.linkedJobs = listResponse.data.selectableJobs;
          let linkedJobtest = this.linkedJobs;
          this.availableLinkedJobs[1] = this.linkedJobs;
          if (this.formType === "UPDATE") {
            var selectedJob = [];
            var selectedAccount = [];
            var linkedAccounts = {};
            var selectedItemJobs = [];
            let selectableAccounts = [];

            selectedItemJobs = listResponse.data.selectedJobs
              .split(", ")
              .map((e) => parseInt(e));

            try {
              const listResponseSelectionItems = await API.getSelectableAccounts(
                this.form
              );
              if (!listResponseSelectionItems || listResponseSelectionItems.error) {
                //error getting data
                console.log(`${listResponseSelectionItems.error}`);
              } else {
                console.log(
                  listResponseSelectionItems.data.accounts.selectableLinkedAccounts
                );
                // convert object to array
                selectableAccounts = {};
                Object.keys(linkedJobtest).map(function (key, index) {
                  selectableAccounts[linkedJobtest[key].id] =
                    listResponseSelectionItems.data.accounts.selectableLinkedAccounts[
                      linkedJobtest[key].id
                      ];
                });
                console.log(selectableAccounts);
                this.linkedAccounts = selectableAccounts;

                if (listResponseSelectionItems.data.accounts.selectedLinkedAccounts) {
                  linkedAccounts = JSON.parse(
                    listResponseSelectionItems.data.accounts.selectedLinkedAccounts
                  );

                  console.log(linkedAccounts);
                }
                if (selectedItemJobs) {
                  this.linkedAccountFields = selectedItemJobs.length;
                  for (var i = 0; i < selectedItemJobs.length; i++) {
                    const linkedId = i + 1;
                    this.availableLinkedJobs[linkedId] = this.linkedJobs;
                    const jobId = selectedItemJobs[i];
                    console.log(jobId);
                    selectedJob[linkedId] = jobId;

                    selectedAccount[jobId] = [];
                    if (linkedAccounts[jobId]) {
                      for (var i = 0; i < linkedAccounts[jobId].length; i++) {
                        const accountIds = linkedAccounts[jobId][i]
                          ? linkedAccounts[jobId][i].accountId
                          : [];
                        selectedAccount[jobId][i] = accountIds;
                        console.log(selectedAccount[jobId]);
                      }
                      console.log(selectedAccount);
                    }
                  }
                }
                this.form.jobs = selectedJob;
                this.form.linkedAccounts = selectedAccount;
                console.log(this.form.linkedAccounts);
              }
            } catch (e) {
              this.loading = false;
              console.log(e);
            }
          }
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        //display error prompt
        this.loading = false;
      }
    },
    save() {
      if (this.$refs.formEntry.validate()) {
        this.isFormComplete = true;
        this.$emit("save-savings-account", this.populatedForm);
        this.$refs.formCard.$el.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    addLinkedAccounts() {
      this.linkedAccountFields++;
    },
    removeLinkedAccounts() {
      this.linkedAccountFields--;
    },
    resetForm() {
      this.$refs.formEntry.reset();
    },
    initializeForm() {
      this.isFormComplete = false;
      if (this.formType === "CREATED") {
        this.form = {
          clientId: 108,
          productId: undefined,
          nominalAnnualInterestRate: undefined,
          interestCalculationType: undefined,
          minRequiredOpeningBalance: undefined,
          interestCompoundingPeriodType: undefined,
          interestPostingPeriodType: undefined,
          interestCalculationDaysInYearType: undefined,
          lockinPeriodFrequency: undefined,
          lockinPeriodFrequencyType: undefined,
          withdrawalFeeForTransfers: false,
          allowOverdraft: false,
          enforceMinRequiredBalance: false,
          minRequiredBalance: 1,
          nominalAnnualInterestRateOverdraft: undefined,
          overdraftLimit: undefined,
          minOverdraftForInterestCalculation: undefined,
          creditLimit: undefined,
          jobs: [],
          linkedAccounts: [],
        };
      } else if (this.formType === "UPDATE" && this.selectedItem) {
        let selectedJob = [];
        let selectedAccount = [];
        console.log("type:", this.formType);
        this.form = {
          id: this.selectedItem.id,
          clientId: this.selectedItem.memberId,
          clientName: this.selectedItem.memberName,
          productId: this.selectedItem.walletId,
          name: this.selectedItem.name,
          active: this.selectedItem.status.active,
          currentStatus: this.getCurrentStatus(),
          nominalAnnualInterestRate: this.selectedItem.nominalAnnualInterestRate,
          interestCalculationType: this.selectedItem.interestCalculationType,
          minRequiredOpeningBalance: this.selectedItem.minRequiredOpeningBalance,
          interestCompoundingPeriodType: this.selectedItem.interestCompoundingPeriodType,
          interestPostingPeriodType: this.selectedItem.interestPostingPeriodType,
          interestCalculationDaysInYearType: this.selectedItem
            .interestCalculationDaysInYearType,
          lockinPeriodFrequency: this.selectedItem.lockinPeriodFrequency,
          lockinPeriodFrequencyType: this.selectedItem.lockinPeriodFrequencyType,
          withdrawalFeeForTransfers: this.selectedItem.withdrawalFeeForTransfers,
          allowOverdraft: this.selectedItem.allowOverdraft,
          enforceMinRequiredBalance: this.selectedItem.enforceMinRequiredBalance,
          minRequiredBalance: this.selectedItem.minRequiredBalance ?? 1,
          nominalAnnualInterestRateOverdraft: this.selectedItem
            .nominalAnnualInterestRateOverdraft,
          overdraftLimit: this.selectedItem.overdraftLimit,
          minOverdraftForInterestCalculation: this.selectedItem
            .minOverdraftForInterestCalculation,
          creditLimit: this.selectedItem.creditLimit,
          jobs: selectedJob,
          linkedAccounts: selectedAccount,
        };
      }
    },
  },
};
</script>

<style scoped>
.stepper-1-btns {
  margin-top: 30px !important;
}

.stepper-2-btns {
  margin-top: 74px !important;
}
</style>
