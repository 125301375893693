<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card ref="formCard">
        <v-card-title>
          <span class="headline">Update Status</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formEntryOne">
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.clientName"
                    readonly="readonly"
                    disabled
                    label="Client"
                    data-cy="client-name"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    disabled
                    readonly="readonly"
                    data-cy="name"
                    label="Wallet"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.currentStatus"
                    disabled
                    label="Current Status"
                    data-cy="current-status"
                    readonly="readonly"
                  />
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="form.newStatus"
                    label="New Status*"
                    required
                    data-cy="new-status"
                    item-text="text"
                    item-value="value"
                    :items="computeStatusList()"
                    :rules="[rules.requiredSelection]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.remarks"
                    label="Remarks"
                  />
                </v-col>
              </v-row>
            </v-container>
            <div class="text-right stepper-1-btns">
              <v-btn
                data-cy="save-status"
                color="primary"
                @click="save"
              >
                Save
              </v-btn>
            </div>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="blue darken-1"
            data-cy="close-account"
            text
            @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import {
  DAYS_IN_YEAR_OPTIONS,
  INTEREST_CALCULATION_METHODS,
  INTEREST_COUMPOUNDING_PERIODS,
  INTEREST_POSTING_PERIODS,
  LOCKIN_PERIOD_FREQUENCY_TYPE,
} from '@/constants/savings_product.js';

export default {
  props: {
    isShow: Boolean,
    formType: String,
    loadingDialog: Boolean,
    selectedItem: Object,
    members: Array,
    wallets: Array,
    accounts: Array,
  },
  data() {
    return {
      isCredit: false,
      linkedAccountFields: 1,
      memberList: this.members ? this.members : [],
      walletList: this.wallets ? this.wallets : [],
      interestCalculationMethodList: INTEREST_CALCULATION_METHODS,
      interestCompoundingPeriodList: INTEREST_COUMPOUNDING_PERIODS,
      interestPostingPeriodList: INTEREST_POSTING_PERIODS,
      daysInYearOptionList: DAYS_IN_YEAR_OPTIONS,
      lockinPeriodFrequencyTypeList: LOCKIN_PERIOD_FREQUENCY_TYPE,
      currency: undefined,
      dialog: this.isShow ?? false,
      loading: this.loadingDialog ?? false,

      linkedJobs: [],
      availableLinkedJobs: [],
      linkedAccounts: this.accounts ? this.accounts : [],

      form: {},
      rules: {
        requiredField: (v) => !!v || 'Required field.',
        requiredSelection: (v) => !!v || 'Required at least one selection',
        properEmail: (v) => !v
          || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)
          || 'E-mail must be valid. Ex. juandelacruz@apollo.com.ph',
      },
      e1: 1,
      isFormComplete: false,
    };
  },
  computed: {
    populatedForm() {
      return {
        id: this.form.id,
        clientId: this.form.clientId,
        productId: this.form.productId,
        formType: this.formType,
        newStatus: this.form.newStatus,
        active: this.form.active,
        remarks: this.form.remarks,

        nominalAnnualInterestRate: this.form.nominalAnnualInterestRate,
        interestCalculationType: this.form.interestCalculationType,
        minRequiredOpeningBalance: this.form.minRequiredOpeningBalance,
        interestCompoundingPeriodType: this.form.interestCompoundingPeriodType,
        interestPostingPeriodType: this.form.interestPostingPeriodType,
        interestCalculationDaysInYearType: this.form.interestCalculationDaysInYearType,
        lockinPeriodFrequency: this.form.lockinPeriodFrequency,
        lockinPeriodFrequencyType: this.form.lockinPeriodFrequencyType,
        withdrawalFeeForTransfers: this.form.withdrawalFeeForTransfers,
        allowOverdraft: this.form.allowOverdraft,
        enforceMinRequiredBalance: this.form.enforceMinRequiredBalance,
        minRequiredBalance: this.form.minRequiredBalance,
        nominalAnnualInterestRateOverdraft: this.form.nominalAnnualInterestRateOverdraft,
        overdraftLimit: this.form.overdraftLimit,
        minOverdraftForInterestCalculation: this.form.minOverdraftForInterestCalculation,
        creditLimit: this.form.creditLimit,
        linkedAccounts: this.form.linkedAccounts,
        jobs: this.form.jobs,
        isCredit: this.isCredit,
      };
    },
  },
  watch: {
    'form.productId': {
      handler(value) {
        const wallet = this.walletList.find((item) => item.id === value);
        if (wallet && wallet.accountType === 'CREDIT') {
          this.isCredit = true;
          this.form.nominalAnnualInterestRate = wallet.nominalAnnualInterest;
          this.form.interestCalculationType = wallet.interestCalculationMethod;
          this.form.interestCompoundingPeriodType = wallet.interestCompoundingPeriod;
          this.form.interestPostingPeriodType = wallet.interestPostingPeriod;
          this.form.interestCalculationDaysInYearType = wallet.daysInYear;
          this.form.allowOverdraft = true;
          this.currency = wallet.currency;
        } else {
          this.isCredit = false;
        }
      },
      deep: true,
    },
    isShow: {
      handler(value) {
        this.dialog = value;
      },
    },
    loadingDialog: {
      handler(value) {
        this.loading = value;
      },
    },
    dialog: {
      handler(value) {
        if (value === true) {
          this.initializeForm();
        }
      },
    },
    members: {
      handler(value) {
        this.memberList = value || [];
      },
    },
    wallets: {
      handler(value) {
        this.walletList = value || [];
      },
    },
    accounts: {
      handler(value) {
        this.linkedAccounts = value || [];
      },
    },
  },
  mounted() {
    this.initializeForm();
  },
  methods: {
    getCurrentStatus() {
      if(this.selectedItem && this.selectedItem.status){
        if (this.selectedItem.status.active) {
          if (this.selectedItem.subStatus.block) {
            return 'BLOCKED';
          }
            return 'ACTIVATED';
          }
          if (this.selectedItem.status.approved) {
            return 'APPROVED';
          }
          if (this.selectedItem.status.closed) {
            return 'CLOSED';
          }
          if (this.selectedItem.status.rejected) {
            return 'REJECTED';
          }
          if (this.selectedItem.status.submittedAndPendingApproval) {
            return 'PENDING APPROVAL';
          }
        return 'YOU HAVE JUST FOUND A BUG';
      }
      
    },
    computeStatusList() {
      if(this.selectedItem && this.selectedItem.status){
      if (this.selectedItem.status.active) {
        if (this.selectedItem.subStatus.block) {
          return [{text: 'UNBLOCK', value: 'unblock'}];
        }
        return [{text: 'BLOCK', value: 'block'}];
      }
      if (this.selectedItem.status.approved) {
        return [
          {text: 'UNDO APPROVAL', value: 'undoApproval'},
          {text: 'ACTIVATE', value: 'activate'},
        ];
      }
      if (this.selectedItem.status.closed) {
        return [{text: 'ACTIVATE', value: 'activate'}];
      }
      if (this.selectedItem.status.rejected) {
        return [{text: 'APPROVE', value: 'approve'}];
      }
      if (this.selectedItem.status.submittedAndPendingApproval) {
        return [{text: 'APPROVE', value: 'approve'}];
      }
      return [{text: 'YOU HAVE JUST FOUND A BUG!', value: 'bug'}];
      }

    },
    close() {
      this.dialog = false;
      this.e1 = 1;
      this.$refs.formCard.$el.scrollIntoView({
        behavior: 'smooth',
      });
      this.$emit('close-dialog', this.formType);
    },
    save() {
      if (this.$refs.formEntryOne.validate()) {
        this.isFormComplete = true;
        this.$emit('save-savings-account', this.populatedForm);
        this.$refs.formCard.$el.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
    resetForm() {
      this.$refs.formEntry.reset();
    },
    initializeForm() {
      this.isFormComplete = false;
      this.form = {
        id: this.selectedItem.id,
        clientId: this.selectedItem.memberId,
        clientName: this.selectedItem.memberName,
        productId: this.selectedItem.walletId,
        name: this.selectedItem.name,
        currentStatus: this.getCurrentStatus(),
      };
    },
  },
};
</script>

<style scoped>
.stepper-1-btns {
  margin-top: 30px !important;
}
</style>
